<template>
  <div style="padding: 15px 40px;min-height: 570px;display: flex;flex-direction: column;align-items: center;justify-content: center" :style="`background:${passcodeBackground};`" v-if="passcode">
    <div style="margin-bottom: 15px;">
      <img :src="passcode.logo" alt="">
    </div>
    <div v-html="passcode.text[landing.current_lang]">
    </div>
    <div :class="{'shake' : animated}" style="display: flex;justify-content: space-evenly;align-items: center;width:60%;margin: 10px auto 25px auto;">
      <div style="height:10px;width:10px;border-radius: 50%;border:1px solid #000;" :style="`background:${+password.length-+item>=0?passcode.active_btn_color:passcode.btn_color};`" v-for="item in passcode.password.length" :key="item" ></div>
    </div>
    <div style="width:100%;margin-bottom:15px;display: flex;align-items: center;justify-content: space-evenly;">
      <div @click="addPassword(1)" style="background:#fff;cursor:pointer;font-size:22px;font-weight:bold;height:50px;width:50px;border-radius: 50%;border:1px solid #000;display: flex;align-items: center;justify-content: center">1</div>
      <div @click="addPassword(2)" style="background:#fff;cursor:pointer;font-size:22px;font-weight:bold;height:50px;width:50px;border-radius: 50%;border:1px solid #000;display: flex;align-items: center;justify-content: center">2</div>
      <div @click="addPassword(3)" style="background:#fff;cursor:pointer;font-size:22px;font-weight:bold;height:50px;width:50px;border-radius: 50%;border:1px solid #000;display: flex;align-items: center;justify-content: center">3</div>
    </div>
    <div style="width:100%;margin-bottom:15px;display: flex;align-items: center;justify-content: space-evenly;">
      <div @click="addPassword(4)" style="background:#fff;cursor:pointer;font-size:22px;font-weight:bold;height:50px;width:50px;border-radius: 50%;border:1px solid #000;display: flex;align-items: center;justify-content: center">4</div>
      <div @click="addPassword(5)" style="background:#fff;cursor:pointer;font-size:22px;font-weight:bold;height:50px;width:50px;border-radius: 50%;border:1px solid #000;display: flex;align-items: center;justify-content: center">5</div>
      <div @click="addPassword(6)" style="background:#fff;cursor:pointer;font-size:22px;font-weight:bold;height:50px;width:50px;border-radius: 50%;border:1px solid #000;display: flex;align-items: center;justify-content: center">6</div>
    </div>
    <div style="width:100%;margin-bottom:15px;display: flex;align-items: center;justify-content: space-evenly;">
      <div @click="addPassword(7)" style="background:#fff;cursor:pointer;font-size:22px;font-weight:bold;height:50px;width:50px;border-radius: 50%;border:1px solid #000;display: flex;align-items: center;justify-content: center">7</div>
      <div @click="addPassword(8)" style="background:#fff;cursor:pointer;font-size:22px;font-weight:bold;height:50px;width:50px;border-radius: 50%;border:1px solid #000;display: flex;align-items: center;justify-content: center">8</div>
      <div @click="addPassword(9)" style="background:#fff;cursor:pointer;font-size:22px;font-weight:bold;height:50px;width:50px;border-radius: 50%;border:1px solid #000;display: flex;align-items: center;justify-content: center">9</div>
    </div>
    <div style="position:relative;width:100%;margin-bottom:15px;display: flex;align-items: center;justify-content: space-evenly;">
      <div @click="addPassword(0)" style="background:#fff;cursor:pointer;font-size:22px;font-weight:bold;height:50px;width:50px;border-radius: 50%;border:1px solid #000;display: flex;align-items: center;justify-content: center">0</div>
      <div @click="clearPassword" style="cursor:pointer;position:absolute;right:35px;font-weight: bold;">Clear</div>
    </div>
  </div>
</template>

<script>
  import {mapState} from 'vuex';
  export default {
    name: "PasscodePreview",
    data(){
      return{
        password:"",
        animated:false,
      }
    },
    methods:{
      clearPassword(){
        this.password = "";
      },
      addPassword(number){
        this.password = this.password + `${number}`;
        if(this.password.length === this.passcode.password.length){
          this.animated = true;
          this.password = "";
          setTimeout(() => {
            this.animated = false
          }, 1000)
        }
      }
    },
    computed:{
      ...mapState('passcode',['passcode']),
      ...mapState(['landing']),
      passcodeBackground(){
        if(this.passcode.background){
          return `url(${this.passcode.background}) no-repeat top center / 100%`
        }else{
          return this.passcode.background_color;
        }
      }
    }
  }
</script>

<style scoped lang="scss">

  .shake {
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
  }
  @keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }
    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }
</style>
